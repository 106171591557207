import React from "react"
import { graphql } from "gatsby"
import LayoutNoStripe from "../../components/layoutNoStripe"
import {
    Section,
    SectionHeading,
  } from "../../styledComponents/section"
import HeroBanner from "../../components/HeroBanner"
import { HeroText, HeroHeader } from "../../styledComponents/heroBanner"  
import { ConsultationSection } from "../../styledComponents/contact"
import SkusCoachingProgram from "../../components/Products/SkusCoachingProgram"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const DistanceHealing = ({ data, location }) => {
  return (
    <>
    <LayoutNoStripe location={location}>

      <HeroBanner 
          image="/distance-healing-karina-grant.jpg" 
          backgroundPosition="55% 0" 
          mediumBackgroundPosition="0 -129px" 
          mediumBackgroundSize="130%" 
          largeBackgroundPosition="0 -160px" 
          largeBackgroundSize="130%"
        >
            <HeroText>
              <HeroHeader>
                  Distance Healing
              </HeroHeader>
            </HeroText>
        </HeroBanner>
      <Section>
        <Heading level={1}>
          
          </Heading>
          <ConsultationSection>
            <p>Distance healing sessions are available with Karina and take place over skype or the phone.</p>
            <p>The speed and effectiveness of distance healing is exactly the same as hands on sessions.</p>
            <p>Distance healing is often preferred by those who wish to enjoy the benefits of a healing session in the comfort of their own home and for those who live further away.</p>
            <SkusCoachingProgram />
          </ConsultationSection>
      </Section>
    </LayoutNoStripe>
    </>
  )
}

export default DistanceHealing

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    
  }
`
