import styled from "styled-components"
import { baseGridStyles } from "./base"
import breakpoints from "./breakpoints"
import { bold11 } from "./typography"

const ProductList = styled.ul`
    
    .order-modal & {
        margin: 0;
        padding: 0 0 8rem 0;
        @media screen and (min-width: ${breakpoints.sm}px) {
            padding: 0 0 5rem 0;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            padding: 0 0 2rem 0;
        }
    }
`

const ProductListItem = styled.li`
    ${baseGridStyles}
    grid-column: span 4;
    border-bottom: 1px solid var(--grey-light);
    padding: 0 0 1.6rem;
    margin-top: 1.6rem;
    grid-row-gap: 0.8rem;

    &:last-child{
        border-bottom: 0;
    }

    @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 0.4rem 0 1.6rem;
        grid-row-gap: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        padding: 2.4rem 0 1.6rem;
        grid-row-gap: 0;
    }

    h4 {
        grid-column: span 4;
        margin: 0;
        line-height: 2;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 1 / 7;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 1 / 13;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            grid-column: span 8;
            text-align: left;
        }

        span {
            color: var(--red-mid);
            display: block;
            text-transform: uppercase;
            font-size: 1.4rem;
            ${bold11}
        }
        
    }
    
    p { // price
        margin: 0;
        margin-bottom: 0;
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 1 / 7;
            text-align: left;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 1 / 13;
        }

    }

    p.event-dates {
        
    }

    div.product-description{ // Description
        margin: 0;
        grid-column: span 4;
        text-align: left;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 1 / 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 1 / 9;
            margin: 1.6rem 0;
        }

    }

    button {
        margin: 0;
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 6 / 7;
        }  
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 10 / 13;
            min-width: auto;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            grid-column: 10 / 13;
        }  
    }
`
const TotalCart = styled.p`
    display: ${props => (props.cartCount !== '0' ? 'block' : 'none')};
    background-color: var(--white);
    left: 0;
    margin-bottom: 3rem;
    padding: 1.6rem 1.6rem 0rem;
    position: fixed;
    right: 0;
    bottom: 4.6rem;
    text-align: right;
    width: auto;
    max-width: 1338px;

    @media screen and (min-width: ${breakpoints.sm}px) {
        background: none;
        padding: 1.6rem 2.4rem 0rem;
        bottom: 5rem;
        border-top: 0px solid var(--grey-light);
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        padding-right: 2.4rem;
        bottom: 5rem;
        color: var(--white);
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        bottom: 5rem;
    }
`

const ProductPrice = styled.p`
    margin: 1.6rem 0 0;
    span {
        color: green;
        font-weight: 700;
    }
`

export {
    ProductList,
    ProductListItem,
    ProductPrice,
    TotalCart
}